import { Col, Row, Typography } from "antd";

import OpenInsureFooter from "../../components/home/Footer";

const { Title, Paragraph } = Typography;

export default function Refund() {
    return (
        <>
            <div style={{ padding: 24 }}>
                <Row>
                    <Col lg={{ span: 4 }} sm={{ span: 22, offset: 2 }}>
                        <Title level={2}>Refund Policy</Title>
                    </Col>
                    <Col lg={{ span: 12 }} sm={{ span: 22, offset: 2 }}>
                        <Title level={4}>About</Title>
                        <Paragraph>
                            At Open Insure, we aim to provide our users with a
                            transparent and equitable framework for managing
                            their self-insurance pools. This Refund Policy
                            outlines the conditions and procedures for refunding
                            contributions made to self-insurance pools.
                        </Paragraph>
                        <Title level={4}>Refund Eligibility</Title>
                        <Paragraph>
                            Refunds of contributions are allowed under the
                            following circumstances:
                        </Paragraph>
                        <ul>
                            <li>
                                <strong>Policy Completion:</strong> Users are
                                eligible for a refund of their remaining
                                contributions at the end of their self-insurance
                                policy term (typically one year). Refunds are
                                issued after the policy has concluded, and any
                                outstanding claims or obligations have been
                                settled.
                            </li>
                            <li>
                                <strong>
                                    Early Termination with Group Approval:
                                </strong>{" "}
                                Users may request a refund before the end of
                                their policy term if they choose to leave the
                                self-insurance pool. Early refunds require
                                written approval from more than 50% of the
                                active members of their group.
                            </li>
                        </ul>
                        <Title level={4}>Claim Submission and Approval</Title>
                        <Paragraph>
                            Users may submit claims for damages to covered
                            devices. Claims are reviewed and decided by a vote
                            of all members in the self-insurance pool. A claim
                            requires a vote of over 50% approval from pool
                            members to be accepted.
                        </Paragraph>
                        <Title level={4}>Refund and Payout Method</Title>
                        <Paragraph>
                            Refunds and claim payouts are issued via check to
                            the mailing address specified by the user. Users are
                            responsible for providing an accurate and up-to-date
                            mailing address to facilitate prompt delivery.
                        </Paragraph>
                        <Title level={4}>Non-Refundable Charges</Title>
                        <Paragraph>
                            All individual charges and transfers processed via
                            Stripe are non-refundable. This includes fees for
                            joining a pool, administrative costs, and other
                            platform-related transactions.
                        </Paragraph>
                        <Title level={4}>Requesting a Refund</Title>
                        <Paragraph>
                            To initiate a refund request, users must:
                        </Paragraph>
                        <ul>
                            <li>
                                Submit a written request via the Open Insure
                                platform.
                            </li>
                            <li>
                                Include necessary documentation, such as proof
                                of group approval (if applicable) or policy end
                                confirmation.
                            </li>
                            <li>
                                Provide their desired mailing address for the
                                refund check.
                            </li>
                        </ul>
                        <Title level={4}>Processing Time</Title>
                        <Paragraph>
                            Refund requests are typically processed within 30
                            business days of receipt of all required
                            documentation and approvals. Delays may occur if
                            additional verification is necessary.
                        </Paragraph>
                        <Title level={4}>Additional Notes</Title>
                        <Paragraph>
                            Refund amounts will be adjusted to account for any
                            outstanding claims, obligations, or administrative
                            fees. Open Insure reserves the right to deny refund
                            requests that do not meet the outlined eligibility
                            criteria or lack proper documentation.
                        </Paragraph>
                    </Col>
                </Row>
            </div>
            <div style={{ marginTop: 100 }} />
            <OpenInsureFooter key="footer" />
        </>
    );
}
